import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import querystring from 'query-string'
import API from '../../components/API'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * Retorna los datos de una remisión
 * @param {int} id - ID de remisión a consultar
 * @returns {Promise}
 */
export function getRemission (id) {
  return axios.get(`${API('remissions')}?id=${id}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Crea una remisión
 * @param {Object} data - Datos de remisión a actualizar
 * @returns {Promise}
 */
export function postRemission (data) {
  return axios.post(API('remissions'), data, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Actualiza una remisión
 * @param {Object} data - Datos de remisión a actualizar
 * @returns {Promise}
 */
export function putRemission (data) {
  return axios.put(API('remissions'), data, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Retorna la lista de remisiones, el backend se encarga de analizar los permisos
 * @param {Object} args - Argumentos para query params
 */
export function getRemissions (args) {
  let params = querystring.stringify(args, { skipNull: true })

  if (params) params = `?${params}`

  return axios.get(`${API('remissions')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Aprueba una remisión como jefe de psicólogas
 * @param {Object} id - Remisión a aprobar
 * @returns {Promise}
 */
export function putCheckRemission (id) {
  return axios.put(`${API('remissions')}?action=check`, { id }, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
