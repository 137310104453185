import React from 'react'

import './styles.scss'

export function Paginate({ total, page, perPage, onPageChange }) {
  const pageCount = Math.ceil(total / perPage)
  let firstRowCurrentPage = 0
  let lastRowCurrentPage = 0

  if (page > 0) {
    firstRowCurrentPage = (page - 1) * perPage + 1
    lastRowCurrentPage = firstRowCurrentPage + perPage - 1
  } else if (total > 0) {
    firstRowCurrentPage = 1
    lastRowCurrentPage = perPage
  }

  if (total < lastRowCurrentPage) {
    lastRowCurrentPage = total
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        PÁG. {page > 1 ? page : 1} de {pageCount ? pageCount : 1}
      </div>

      <div style={{ margin: '0 2em' }}>
        {firstRowCurrentPage}-{lastRowCurrentPage} de {total}
      </div>

      <div className="paginate">
        <div
          className="double-arrow double-arrow-left"
          onClick={() => {
            if (page > 1) onPageChange(1)
          }}
        >
          <div className="icon-arrow" />
          <div className="icon-arrow" />
        </div>

        <div
          className="icon-arrow icon-arrow-left"
          onClick={() => {
            if (page > 1) onPageChange(page - 1)
          }}
        />

        <div
          className="icon-arrow icon-arrow-right"
          onClick={() => {
            if (pageCount > 1 && page < pageCount) {
              onPageChange(page + (page === 0 ? 2 : 1))
            }
          }}
        />

        <div
          className="double-arrow double-arrow-right"
          onClick={() => {
            if (pageCount > 1 && page < pageCount) onPageChange(pageCount)
          }}
        >
          <div className="icon-arrow" />
          <div className="icon-arrow" />
        </div>
      </div>
    </div>
  )
}
