import React from 'react'
import './styles.scss'

export default function(props) {
  return (
    <label className="checkbox-input">
      <input type="checkbox" {...props} />
      <div className="custom-style"></div>
    </label>
  )
}
